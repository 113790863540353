import React from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Layout from '../../../components/Layout';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '100px'
  },
  img: {
    maxWidth: '100%'
  },
  remark: {
    whiteSpace: 'pre',
    color: 'black',
    background: '#dbe7f3',
    padding: 10,
    borderRadius: 4
  },
}));


export default () => {
  const fileInput = React.useRef(null);
  const [file, setFile] = React.useState();

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ยืนยันตัวตน KYC - MorOnline</title>
      </Helmet>

      <Box p={2}>
        <h2>ยืนยันตัวตนก่อนพบแพทย์</h2>
        <p>เพื่อความปลอดภัยของผู้ใช้บริการและแพทย์ผู้ให้คำปรึกษา ทางเราจำเป็นที่จะต้องยืนยันตัวตนของท่านก่อนพบแพทย์ของเรา</p>
        <Paper>
        <Box p={2}>
          <img src="/img/idcard.png" width="100%"></img>
          <p>กรุณาเลือกรูปด้านหน้าของบัตรประชาชน รูปบัตรควรเห็นตัวอักษรชัดเจน</p>
          <div className="file-uploader">
            <input
              ref={fileInput}
              type="file"
              accept="image/*"
              onChange={handleFileInput}
              style={{ display: 'none' }}
            />
            <Button size="small" color="secondary" variant="contained" onClick={e => fileInput.current && fileInput.current.click()}>
              เลือกรูป
            </Button>
            <span> {file && file.name}</span>
          </div>
        </Box>
        </Paper>
        <Box mt={4}>
          <Link to="/consult/patientApp/kycSuccess">
            <Button
              fullWidth
              color="primary"
              variant="contained"
              disabled={!file}
            >
              ยืนยัน
            </Button>
          </Link>
        </Box>
      </Box>
    </Layout>
  );
};